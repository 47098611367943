/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap");

@font-face {
  font-family: "Antapani";
  src: local("Antapani"), url(./Images/Antapani.otf) format("truetype");
}

.font-antapani {
  font-family: "Antapani", "Poppins", sans-serif;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

/* width */
*::-webkit-scrollbar {
  width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: rgb(160, 149, 149);
  border-radius: 4px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
